import ujs from '@rails/ujs'
ujs.start()

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import './globals/jquery'
import './globals/moment'

import 'bootstrap'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/solid.css'

// jquery-ui must be imported in this file to ensure it's loaded after jquery is added to window
import 'jquery-ui/ui/data'
import 'jquery-ui/ui/scroll-parent'
import 'jquery-ui/ui/version'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/widgets/mouse'
import 'jquery-ui/ui/widgets/sortable'

import '#/layouts/js'
import '#/course_enrollments/js'
import '#/enrollment_exams/js'
import '#/exam_questions/js'
import '#/exams/js'
import '#/lectures/js'
import '#/outside_pages/js'
import '#/payments/js'
import '#/registrar/js'
import '#/students/js'
import '#/translations/js'
