import './uploads'

let count = 0

$(document).on('cocoon:before-insert', '.lecture-extensions', function(e, insertedItem, origEvent) {
  if (origEvent.target.matches('[data-count]')) {
    const lectures = e.currentTarget.getAttribute('data-lectures').split(',')
    insertedItem.each(function(_, el) {
      if (el.matches && el.matches('.nested-fields')) {
        el.querySelector(`select option[value="${lectures[count]}"]`).setAttribute('selected', 'selected')
      }
    })
    count++
  }

  if (count == parseInt(origEvent.target.getAttribute('data-count'))) {
    count = 0
  }
})

$(document).on('click', '#private-files span.btn-danger', function() {
  this.closest('.card').remove()
})
